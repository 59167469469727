const mobileMenu = {
    button: $('#mobile-menu-button'),
    nav: $('#mobile-menu-nav'),
};

mobileMenu.button.on('click', () => {
    if (mobileMenu.nav.hasClass('d-none')) {
        mobileMenu.nav.removeClass('d-none');
    } else {
        mobileMenu.nav.addClass('d-none');
    }
});
